@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* official color codes
#2F7F0E  green
#DD8A37   orange
#666666   gray */

/* ============================= */
/* NAVBAR */
/* ============================= */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 1px 3px 0 lightgray;
  /* background-color: red; */
}

.nav-link {
  font-weight: 500 !important;
}

.active {
  border-bottom: 1px solid #2f7f0e;
  border-top: 1px solid #2f7f0e;
}

.navbar-toggler {
  border: none;
}

.menu {
  height: 3.3px;
  width: 32px;
  background-color: #2f7f0e;
  border-radius: 99px;
  margin: 4px 0;
  display: block;
}

/* ============================= */
/* FOOTER */
/* ============================= */
.footer {
  color: white;
  padding: 25px;
  background: linear-gradient(to right, #434343, #000000);
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover {
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}

/* ============================= */
/* PAGE NOT FOUND */
/* ============================= */
.pnf {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ============================= */
/* HOME PAGE */
/* ============================= */
.topSection {
  padding: 0 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  width: 100%;
}

.headingA {
  font-size: 14px;
  text-align: justify;
  color: #2f7f0e;
  font-weight: 500;
}

.paragraph {
  font-size: 14px;
  text-align: justify;
  font-weight: 500;
}

.paragraphFaq {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
}

#howIt .paragraph {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
}

.paraHeading {
  font-size: 14px;
  text-align: justify;
  font-weight: 500;
  margin-bottom: 16px;
  /* margin-top: 40px; */
  font-weight: 700;
}

.paraHeadingPTag {
  font-size: 14px;
  text-align: justify;
  font-weight: 500;
  margin-bottom: 16px;
  /* margin-top: 40px; */
  font-weight: 700;
}

.paraList {
  font-size: 14px;
  text-align: justify;
  font-weight: 500;
  margin-bottom: 16px;
}

.aTags {
  text-decoration: none;
  color: #dd8a37;
}

/* card section */

.progress {
  width: 100px;
  height: 100px;
  /* line-height: 100px; */
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.progress:after {
  content: "";
  width: 95%;
  height: 95%;
  border-radius: 50%;
  border: 1px solid #dd8a37;
  position: absolute;
  top: 2.5%;
  left: 2.5%;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 7px;

  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.486);
  line-height: 10px;
  text-align: center;
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  padding-top: 16px;
}

.progress.green .progress-bar {
  border-color: #2f7f0e;
}

.progress.green .progress-left .progress-bar {
  animation: loading-2 1.7s linear forwards 1.8s;
}

.progress.light .progress-bar {
  border-color: #2f7f0e;
}

.progress.light .progress-left .progress-bar {
  animation: loading-3 1.7s linear forwards 1.8s;
}

.progress.pink .progress-bar {
  border-color: #2f7f0e;
}

.progress.pink .progress-right .progress-bar {
  animation: loading-4 3.5s linear forwards 0s;
}

.progress.red .progress-bar {
  border-color: #2f7f0e;
}

.progress.red .progress-left .progress-bar {
  animation: loading-5 1.7s linear forwards 1.8s;
}

.progress.blue .progress-bar {
  border-color: #2f7f0e;
}

.progress.blue .progress-right .progress-bar {
  animation: loading-6 3.5s linear forwards 0s;
}

.progress.black .progress-bar {
  border-color: #2f7f0e;
}

.progress.black .progress-right .progress-bar {
  animation: loading-7 3.5s linear forwards 0s;
}

.progress.white .progress-bar {
  border-color: #2f7f0e;
}

.progress.white .progress-right .progress-bar {
  animation: loading-8 3.5s linear forwards 0s;
}

.progress.gray .progress-bar {
  border-color: gray;
}

.progress.gray .progress-left .progress-bar {
  animation: loading-9 1.7s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(137deg);
    transform: rotate(137deg);
  }
}

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(79deg);
    transform: rotate(79deg);
  }
}

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(64deg);
    transform: rotate(64deg);
  }
}

@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(162deg);
    transform: rotate(162deg);
  }
}

@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
}

@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media only screen and (max-width: 990px) {
  .progress {
    margin-bottom: 20px;
  }
}

.imageDiv {
  position: relative;
  padding: 0 8px;
}

.tagPercentage {
  float: right;
  position: absolute;
  right: 28px;
  top: 80px;
  z-index: 1;
}


.extraText {
  float: right;
  position: absolute;
  right: 51px;
  top: 100px;
  z-index: 2;
}

.extraText2 {
  float: right;
  position: absolute;
  right: 53px;
  top: 135px;
  z-index: 2;
}

.extraText11 {
  float: right;
  position: absolute;
  right: auto;
  left: 160px;
  top: 180px;
  z-index: 2;
}

.extraText22 {
  float: right;
  position: absolute;
  right: auto;
  left: 160px;
  top: 195px;
  z-index: 2;
}


.completionDate {
  float: right;
  position: absolute;
  right: auto;
  left: 160px;
  top: 159px;
  z-index: 2;
}

.extraText3 {
  float: right;
  position: absolute;
  right: 22px;
  top: auto;
  bottom: 0;
  z-index: 2;
}

.extraText4 {
  float: right;
  position: absolute;
  right: auto;
  left: 22px;
  top: auto;
  bottom: 0;
  z-index: 2;
}

.extraText5 {
  float: right;
  position: absolute;
  right: auto;
  left: 160px;
  top: 142px;
  z-index: 2;
  
}

.eg {
  float: right;
  position: absolute;
  right: auto;
  left: 290px;
  top: 78px;
  z-index: 2;
}

.extraText6 {
  float: right;
  position: absolute;
  right: auto;
  left: 22px;
  top: 80px;
  z-index: 10;
}

.tagButton {
  float: right;
  position: absolute;
  right: 25px;
  top: auto;
  bottom: 21px;
  z-index: 1;
}

.tagButtonCompleted {
  float: right;
  position: absolute;
  right: 25px;
  top: auto;
  bottom: 26px;
  z-index: 1;
}

.tagInitiative {
  position: absolute;
  padding-left: 5px;
  padding-right: 20px;
  top: 4px;
  z-index: 1;
  line-height: 1.2;
  width: 100%;
}

.tagOutOf {
  float: left;
  position: absolute;
  left: 23px;
  top: auto;
  bottom: 10px;
  z-index: 1;
  line-height: 1;
}


.tagDollar {
  float: left;
  position: absolute;
  left: 23px;
  top: auto;
  bottom: 40px;
  z-index: 1;
  line-height: 1;
}


.tagDollar44 {
  float: left;
  position: absolute;
  right: auto;
  left: 160px;
  top: 80px;
  z-index: 1;
  line-height: 1;
}

.tagDollarCompleted {
  float: left;
  position: absolute;
  left: 23px;
  top: auto;
  bottom: 26px;
  z-index: 1;
  line-height: 1;
}

.card {
  box-shadow: 0 0 4px rgb(0 0 0 / 40%);
  border-radius: 5px;
}

.ora {
  text-decoration: none;
  color: white;
  font-size: 24px;
  background-color: #dd8a37;
  padding: 0 1px;
  margin: 0 1px;
  float: left;
  font-weight: 600;
}

.oraDup {
  text-decoration: none;
  color: white;
  font-size: 26px;
  background-color: #dd8a37;
  padding: 0 1px;
  margin: 0 1px;
  float: left;
  font-weight: 600;
}

.btStandardFill {
  color: white;
  border-color: #2f7f0e;
  background-color: #2f7f0e;
  border-radius: 5px;
  height: 1.8rem;
  width: 6.9rem;
  padding: 4px 0 0 0;
  font-size: 0.8rem;
}

.btt {
  height: 1.8rem;
  width: 6.9rem;
  padding: 0;
}

.btStandardFillGray {
  color: white;
  border-color: #666666;
  background-color: #666666;
  border-radius: 5px;
  height: 1.8rem;
  width: 6.9rem;
  padding: 0;
  font-size: 0.8rem;
}

.btStandardFill.btn:hover {
  background-color: #2f7f0e !important;
  border-color: #2f7f0e !important;
  color: white;
}

.btStandardFillGray.btn:hover {
  background-color: #666666 !important;
  border-color: #666666 !important;
  color: white !important;
}

.btStandardFillGreen.btn:hover {
  background-color: #666666 !important;
  border-color: #666666 !important;
  color: white !important;
}

.cardMain {
  padding: 35px 8px 21px 8px;
  background-color: "white";
  min-height: 190;
}

.cont {
  position: relative;
  padding: 0px;
}

.overlayImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.5;
  z-index: 0;
}

.overlayImgCart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.5;
  z-index: 0;
  border-radius: 5px;
}

/* //////////////////////////////////// */
/* Participation */
/* /////////////////////////////////// */
.pTagParticipation {
  float: left;
  position: absolute;
  left: 23px;
  top: 80px;
  z-index: 1;
  line-height: 2.5;
  font-weight: 500;
}

.boxRight {
  float: left;
  position: absolute;
  right: 23px;
  top: 80px;
  z-index: 1;
  line-height: 2.5;
}

.boxRight p {
  border: thin solid #2f7f0e;
  padding: 0 25px;
  border-radius: 5px;
}

.completed .comp {
  filter: grayscale(100%);
}

/* .randomPicker{
  filter: grayscale(0%);
} */

.referral-table {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

/* ////////////////////////////////// */
/* Account */
/* /////////////////////////////////// */

.accountLabel {
  font-size: 14px;
  font-weight: 500;
}

.accountInput {
  font-size: 14px;
  font-weight: 500;
  /* border-color: #2f7f0e; */
  border: thin solid #2f7f0e;
}

.dpdown {
  /* border:thin solid #2f7f0e; */
  /* border-radius:5px; */
  font-size: 14px;
  font-weight: 500;
}

.nominateInput {
  font-size: 14px;
  font-weight: 500;
  border: "thin solid #2f7f0e";
  border-color: #2f7f0e !important;
}

.customBorder {
  border: thin solid black;
}

/* /////////////////profile page */
.profile-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  /* background-color: white; */
}

.profile-heading {
  text-align: center;
  margin-bottom: 20px;
}

.profile-info {
  background-color: white;
  box-shadow: 0 1px 3px 0 lightgray;
  padding: 20px;
  border-radius: 8px;
}

.profile-email {
  margin-bottom: 10px;
}

.profile-bio {
  font-style: normal;
}

/* ///////////// */
/* CONTACT */

/* Style inputs */
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  margin-top: 3px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  color: white;
  border-color: #2f7f0e;
  background-color: #2f7f0e;
  border-radius: 5px;
  height: 1.8rem;
  width: 6.9rem;
  padding: 0 0 0 0;
  font-size: 0.8rem;
}

input[type="submit"]:hover {
  background-color: #2f7f0e !important;
  border-color: #2f7f0e !important;
  color: white;
}

/* Style the container/contact section */
/* .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 10px;
  } */

/* Create two columns that float next to eachother */
.column {
  float: left;
  width: 50%;
  margin-top: 6px;
  padding: 13px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
/* @media screen and (max-width: 600px) { */
.column,
input[type="submit"] {
  width: 100%;
  margin-top: 0;
}
/* } */
/* 
.ol {
  counter-reset: item;
}
li {
  display: block;
  color: #666666;
}
li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
} */
/* 
li::marker {
  content: counters(item, ".") " ";
} */

/* .dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background: "none";
} */

.dropdown-item :active {
  text-decoration: none;
  background: "none";
}

.row input:focus {
  box-shadow: none;
}

.row textarea:focus {
  box-shadow: none;
}

.row select:focus {
  box-shadow: none;
}

/* .row select:hover option {
  background-color: yellow;
  color: red; 
} */


.green-checkbox {
 
  width: 30px;
  height: 30px;
  border: 2px solid #2F7F0E;
  background-color: #2F7F0E;
  outline: none;
  margin: 10px;
  --checkbox-color: green;
}


.unscrollable-section {
  position: fixed;
  top: -30px;
  left: 0;
  width: 100%;
  height: 105px;
  background-color: #F8F8FE;
  z-index: 999;
  overflow: hidden; /* Hide any potential content overflow */
}

.no-scroll {
  overflow: hidden;
}